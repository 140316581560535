export const reinigerData =[
    {
      "Name": "Grillreiniger für lackierte und pulverbeschichtete Oberflächen",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Grillreiniger-lackierte-pulverbeschichtete-Oberflaechen.php",
      "Artikelnummer": "100366",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger",
      "Anwendungsbereich": "Öl- und Fettverschmutzungen, Eiweiß-, Ruß-, Lebensmittelverschmutzungen, organische Rückstände aller Art",
      "Geeignet für": "Grills, Seitenablagen, Arbeitsflächen, Seitenkocher, Outdoor Küchen, Arbeitsplatten",
      "Oberfläche/Material": "lackierte/pulverbeschichtete Oberflächen, Keramik, Emaille, Kunststoff, Edelstahl, Naturstein, Quarz-Komposit, Glas, HPL, Dekton",
      "Besonderheiten": "Intensive Wirkung, materialschonend, ohne Säure oder schleifende Partikel, umweltneutral",
      "Reinigung": 3,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Grillreiniger-lackierte-pulverbeschichtete-Grills-0-1720799878.jpg.webp"
    },
    {
      "Name": "Grillreiniger für Edelstahloberflächen",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Grillreiniger-Edelstahloberflaechen.php",
      "Artikelnummer": "100365",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger",
      "Anwendungsbereich": "Öl- und Fettverschmutzungen, Schmierfilme, Fingerabdrücke, Streifen",
      "Geeignet für": "Grills, Spülen, Herde, Einbaugeräte, Seitenablagen, Bedienblenden und Armaturen",
      "Oberfläche/Material": "matte/polierte Edelstahloberflächen",
      "Besonderheiten": "Intensive Wirkung, materialschonend, gleichmäßiger Glanz ohne Streifen, Optik 'wie neu'",
      "Reinigung": 3,
      "Pflege": 3,
      "Schutz": "x",
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Grillreiniger-fuer-Edelstahloberflaechen-100365-0-1720800112.jpg.webp"
    },
    {
      "Name": "Grillreiniger für Aluminium-Komponenten",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Grillreiniger-Aluminium-Druckguss.php",
      "Artikelnummer": "100364",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger",
      "Anwendungsbereich": "Öl- und Fettverschmutzungen, Eiweiß-, Ruß-, Lebensmittelverschmutzungen, organische Rückstände aller Art",
      "Geeignet für": "Alu-Druckguss-Komponenten wie z.B. Deckel-Seitenteile, Brennerwannen",
      "Oberfläche/Material": "Aluminiumoberflächen, Aluminium-Druckguss",
      "Besonderheiten": "Intensive Wirkung, materialschonend, ohne Säure oder schleifende Partikel, umweltneutral",
      "Reinigung": 3,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Grillreiniger-Aluminium-Komponenten-100364-Flasche.jpg.webp"
    },
    {
      "Name": "Grillreiniger für Emaille und Keramikgrills",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Grillreiniger-Emaille-Keramikgrills.php",
      "Artikelnummer": "100357",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger",
      "Anwendungsbereich": "Öl- und Fettverschmutzungen, Eiweiß-, Ruß-, Lebensmittelverschmutzungen, organische Rückstände aller Art",
      "Geeignet für": "emaillierte Oberflächen, Keramikbeschichtungen",
      "Oberfläche/Material": "Aluminiumoberflächen, Aluminium-Druckguss",
      "Besonderheiten": "Intensive Wirkung, materialschonend, ohne schleifende Partikel, mit Zitrusduft",
      "Reinigung": 3,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Grillreiniger-Emaille-Keramikgrills-100357-Flasche.jpg.webp"
    },
    {
      "Name": "Universal Schonreiniger für Grillgeräte",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Universal-Schon-Reiniger-Grillgeraete.php",
      "Artikelnummer": "100351",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Aktiv-Schaumreiniger",
      "Anwendungsbereich": "Öl- und Fettverschmutzungen, Eiweiß-, Ruß-, Lebensmittelverschmutzungen, organische Rückstände aller Art",
      "Geeignet für": "Grills aller Art, Grillgehäuse, Deckel, Brennerwanne, Seitenablagen, Arbeitsflächen",
      "Oberfläche/Material": "lackierte/pulverbeschichtete Oberflächen, Edelstahl, Glas, Keramik, Emaille",
      "Besonderheiten": "Selbsttätige Wirkung direkt bei Ausprühen, Schmutz löst sich 'wie von selbst', dennoch absolut materialschonend",
      "Reinigung": 4,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Universal-Schonreiniger-Dose-Grillgeraete-100351.jpg.webp"
    },
    {
      "Name": "Universal Power Reiniger für Grills und Roste",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Universal-Power-Reiniger-Grillgeraete.php",
      "Artikelnummer": "100350",
      "Behälter": "Sprühflasche mit Schaumkopf",
      "Reiniger-Art": "Aktiv-Schaumreiniger",
      "Anwendungsbereich": "Stark Eingebranntes, Fett, Öl, Ruß, Rauchharz",
      "Geeignet für": "Edelstahl-Grills, Grillroste, Brennerwannen, Grillgehäuse, Arbeitsflächen, Seitenablagen",
      "Oberfläche/Material": "Edelstahl, Keramik, Glas",
      "Besonderheiten": "für echte Härtefälle, besonders kraftvoll",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Universal-Power-Reiniger-100350-Flasche.jpg"
    },
    {
      "Name": "Vorreiniger und Krustenbrecher für Geschirrspüler",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Vorreiniger-Krustenbrecher.php",
      "Artikelnummer": "100361",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger, Intensivreiniger",
      "Anwendungsbereich": "Stark eingebrannte Grillrückstände, Öl- und Fettverschmutzungen, Eiweiß-, Ruß- und Lebensmittelverschmutzungen",
      "Geeignet für": "Stark verkrustetes Grillzubehör, Grillbesteck, Bräter, Fettauffangwannen",
      "Oberfläche/Material": "Edelstahl, Keramik, Glas",
      "Besonderheiten": "Selbsttätige Wirkung, Verkrustungen werden effektiv gelöst, kein Schrubben und Scheuern mehr",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Vorreiniger-Krustenbrecher-100361-Flasche.jpg"
    },
    {
      "Name": "BBQ Rauchharz Entferner",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/BBQ-Rauchharz-Entferner.php",
      "Artikelnummer": "100359",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Aktiv-Schaumreiniger",
      "Anwendungsbereich": "Hartnäckige Verkrustungen, Rauchharz, eingebrannte Lebensmittel, Ruß- und Fettrückstände",
      "Geeignet für": "Grilldeckel, Dunstabzughauben, Räucheröfen, Backöfen",
      "Oberfläche/Material": "Edelstahl, Keramik, Glas, emaillierte Oberflächen (nicht auf Aluminium anwenden!)",
      "Besonderheiten": "Stabiler Schaum mit guter Haftwirkung, auch für senkrechte Oberflächen",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Reiniger-BBQ-Rauchharz-Entferner-100359-Dose.jpg"

    },
    {
      "Name": "Power Schaumreiniger",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Zubehoer/Power-Schaumreiniger-300ml.php",
      "Artikelnummer": "100269",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Aktiv-Schaumreiniger",
      "Anwendungsbereich": "Fett, Ruß, Ölverschmutzungen",
      "Geeignet für": "Grilldeckel, Sichtscheiben",
      "Oberfläche/Material": "Edelstahl, Glas, emaillierte Oberflächen",
      "Besonderheiten": "Stabiler Schaum mit guter Haftwirkung, auch für senkrechte Oberflächen",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Power-Schaumreiniger-300ml-100269.jpg"
    },
    {
      "Name": "Keramikbrenner Reiniger",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Keramikbrenner-Reiniger.php",
      "Artikelnummer": "100356",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Druckluft",
      "Anwendungsbereich": "Asche, verbrannte Rückstände, Staub",
      "Geeignet für": "Keramikbrenner, Brennerdüsen",
      "Oberfläche/Material": "alle Materialien",
      "Besonderheiten": "sehr effektiv, hygienisch und ohne flüssige Substanzen sehr schonend zum Brenner",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Keramikbrenner-Reiniger-100356-Dose.jpg"
    },
    {
      "Name": "Fett- und Ölfleckenentferner",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Fett-und-Oelfleckentferner.php",
      "Artikelnummer": "100355",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Spray mit selbsttätiger Wirkung",
      "Anwendungsbereich": "Fett- und Ölverschmutzungen, Marinadereste",
      "Geeignet für": "Terrassenboden, Gehwegplatten, Hof- und Garageneinfahrten, Pflastersteine, Verbundsteine, Betonsteine, Natursteine",
      "Oberfläche/Material": "Steinoberflächen, alle säureempflindliche Steinoberflächen, Marmor, Granit, Schiefer, Kalk- und Sandstein, Betonwerkstein, Terrazzo",
      "Besonderheiten": "Selbsttätige, zuverlässige Wirkung, ohne Schrubben und Scheuern, materialschonend",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/100355-Grillfuerst-Oel-Fleckenentferner-500ml.jpg"
    },
    {
      "Name": "Rostfleckenentferner",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Rostflecken-Entferner.php",
      "Artikelnummer": "100353",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger mit selbsttätiger Wirkung",
      "Anwendungsbereich": "Rostflecken",
      "Geeignet für": "Fliesen, Stein- und Keramikoberflächen",
      "Oberfläche/Material": "alle säureempflindliche Steinoberflächen, Marmor, Granit, Schiefer, Kalk- und Sandstein, Betonwerkstein, Terrazzo",
      "Besonderheiten": "Selbsttätige Wirkung, schnell und effektiv, sehr zuverlässig, materialschonend",
      "Reinigung": 5,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Rostfleckenentferner-100353-Spruehflasche.jpg"
    },
    {
      "Name": "Desinfektionsreiniger",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Desinfektionsreiniger.php",
      "Artikelnummer": "100354",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger mit selbsttätiger Wirkung",
      "Anwendungsbereich": "Entfernt Lebensmittelrückstände, unangenehme Gerüche, ölige/fettige Verschmutzungen",
      "Geeignet für": "Arbeitsflächen, Vakuumierer, Sous-Vide-Garer, Kühlschränke, Kühlboxen und - taschen, Schneidemaschinen",
      "Oberfläche/Material": "abwaschbare Oberflächen",
      "Besonderheiten": "Antibakterieller Desinfektionsreiniger für abwaschbare Oberflächen von hygienesensiblen Küchengeräten und Grillzubehör ",
      "Reinigung": 2,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Desinfektionsreiniger-Spruehflasche-100354.jpg"
    },
    {
      "Name": "Blazinglas Reiniger",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Blazinglas-Reiniger.php",
      "Artikelnummer": "100352",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger mit selbsttätiger Wirkung",
      "Anwendungsbereich": "Ruß, Fett, Öl, Verbrennungsrückstände",
      "Geeignet für": "Blazinglas, Ofenglas, Sichtscheibe Gasgrill, Glaskeramik-Kochfeld",
      "Oberfläche/Material": "Glaskeramik, Glas, Edelstahl, Ofenglas, Keramikoberflächen",
      "Besonderheiten": "Löst selbst harte Verkrustungen zuverlässig ohne Scheuern",
      "Reinigung": 4,
      "Pflege": 0,
      "Schutz": null,
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Blazinglas-Reiniger-100352-Spruehflasche.jpg"
    },
    {
      "Name": "Schutzimprägnierung für Grillgeräte",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Schutzimpraegnierung-Grillgeraete.php",
      "Artikelnummer": "100362",
      "Behälter": "Sprühflasche",
      "Reiniger-Art": "Flüssigreiniger zum Auspolieren",
      "Anwendungsbereich": "Zum Schutz vor Wasser, Verschmutzungen und Flecken",
      "Geeignet für": "Grills aller Art, Seitenablagen, Outdoorküchen, Oberflächen im Innen- und Außenbereich",
      "Oberfläche/Material": "Edelstahl, Emaille, Keramik, Glas",
      "Besonderheiten": "hitzebeständig bis 400°C, mit Lotuseffekt",
      "Reinigung": 0,
      "Pflege": 5,
      "Schutz": "x",
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Schutzimpraegnierung-fuer-Grillgeraete-100362-Flasche.jpg"
    },
    {
      "Name": "Profi-Pflegespray für Grillgeräte",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Profi-Pflegespray-Grillgeraete-Edelstahl.php",
      "Artikelnummer": "100358",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Flüssigreiniger zum Auspolieren",
      "Anwendungsbereich": "Entfernt Fingerabdrücke, Streifen, Öl und Fett. Schützt die Oberfläche vor Neuverschmutzung",
      "Geeignet für": "Edelstahlgrills und Edelstahloberflächen aller Art",
      "Oberfläche/Material": "matte/polierte Edelstahloberflächen",
      "Besonderheiten": "Strahlender Glanz, aufgefrischte Oberfläche, Abperleffekt",
      "Reinigung": 2,
      "Pflege": 4,
      "Schutz": "x",
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Profi-Pflegespray-Grillgeraete-100358-Dose.jpg"
    },
    {
      "Name": "Reinigungspolitur für Grillgeräte",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Reinigungspolitur-Grillgeraete.php",
      "Artikelnummer": "100363",
      "Behälter": "Schraubflasche",
      "Reiniger-Art": "Politur",
      "Anwendungsbereich": "Entfernt Rostflecken, Mikrokratzer und grobe Verschmutzungen, Eingebranntes, Fett und Kalkflecken",
      "Geeignet für": "Edelstahlgrills, Arbeitsflächen",
      "Oberfläche/Material": "Edelstahl",
      "Besonderheiten": "wasser- und schmutzabweisende Schutzschicht",
      "Reinigung": 3,
      "Pflege": 5,
      "Schutz": "x",
      "Image":"https://www.grillfuerst.de/media/images/borlabs-grid/products/info/Grillfuerst-Grillreiniger-lackierte-pulverbeschichtete-Grills-0-1720799878.jpg.webp"
    },
    {
      "Name": "Imprägnierung für Grill-Abdeckhauben",
      "Link": "https://www.grillfuerst.de/Eigenmarke/Grillreiniger/Impraegnierung-Grill-Abdeckhauben.php",
      "Artikelnummer": "100360",
      "Behälter": "Druckgasdose",
      "Reiniger-Art": "Spray",
      "Anwendungsbereich": "Verhindert das Eindringen von Wasser, Schmutz, Öl und Fett auch unter extremen Bedingungen, Schützt vor Schmutz, Stockflecken und Schimmelbildung",
      "Geeignet für": "Grill-Abdeckhaube, Grillschürze",
      "Oberfläche/Material": "Textilien",
      "Besonderheiten": "Extra starke Schutzimprägnierung, farblos und UV-beständig",
      "Reinigung": 0,
      "Pflege": 5,
      "Schutz": "x",
      "Image":"https://www.grillfuerst.de/media/images/org/Grillfuerst-Impraegnierung-Grill-Abdeckhauben-100360.jpg"
    }
  ]
  