<template>
  <div class="grillreiniger-filter">
    <h1>Finde den richtigen Grillreiniger</h1>
    <h2 @click="zeigealle" >{{ resultCount }} Reiniger gefunden</h2>
    <!-- Schritt 1: Materialauswahl -->
    <div v-if="currentStep === 1" class="step">
      <h3>1. Welches Material möchtest Du reinigen?</h3>
      <select v-model="selectedMaterial">
        <option value="">Alle Materialien</option>
        <option value="Edelstahl">Edelstahl</option>
        <option value="Keramik">Keramik</option>
        <option value="Aluminium">Aluminium</option>
        <option value="lackierte/pulverbeschichtete Oberflächen">
          lackierte /puklverbeschichtete Oberflächen
        </option>
        <option value="Glas">Glas</option>
      </select>
     
      <button @click="zeigealle">Zeige {{ resultCount }} Grillreiniger</button>
      <button @click="nextStep">Weiter</button>
      
    </div>

    <!-- Schritt 2: Verschmutzungsart -->
    <div v-if="currentStep === 2" class="step">
      <h3>2. Welche Art von Verschmutzungwn möchtest Du beseitigen?</h3>
      <select v-model="selectedDirtType">
        <option value="">Alle Verschmutzungsarten</option>
        <option value="Öl- und Fettverschmutzungen">Öl- und Fettverschmutzungen</option>
        <option value="Ruß">Ruß</option>
        <option value="Stark Eingebranntes">Stark Eingebranntes</option>
        <option value="Lebensmittelverschmutzungen">Lebensmittelverschmutzungen</option>
      </select>
      <button @click="start">Zum Anfang</button>
      <button @click="previousStep">Zurück</button>
      <button @click="zeigealle">Zeige {{ resultCount }} Grillreiniger</button>
      <button @click="nextStep">Weiter</button>
      
    </div>

    <!-- Schritt 3: Schutz/Pflege -->
    <div v-if="currentStep === 3" class="step">
      <h3>3. Brauchst du zusätzlichen Schutz oder Pflege?</h3>
      <select v-model="needsProtection">
        <option value="">Schutz/Pflege egal</option>
        <option value="Ja">Ja</option>
        <option value="Nein">Nein</option>
      </select>
      <button @click="start">Zum Anfang</button>
      <button @click="previousStep">Zurück</button>
      <button @click="zeigealle">Zeige {{ resultCount }} Grillreiniger</button>
    </div>

    <!-- Schritt 4: Ergebnisanzeige -->
    <div v-if="currentStep === 4" class="step ">
      <button @click="start">Zum Anfang</button>
      <button @click="previousStep">Zurück</button>
      <div
      v-for="reiniger in filteredReiniger"
      :key="reiniger.Artikelnummer"
      class="reiniger-item"
    >
        <div class="flex">
            <div class="cardlinks">    
                <h3>
                    <a :href="reiniger.Link" target="_blank">{{ reiniger.Name }}</a>
                </h3>
                <p><strong>Anwendungsbereich:</strong> {{ reiniger.Anwendungsbereich }}</p>
                <p><strong>Besonderheiten:</strong> {{ reiniger.Besonderheiten }}</p>
                <p><strong>Reiniger Art:</strong> {{ reiniger["Reiniger-Art"]}}</p>
                <p><strong>Artikelnummer:</strong> {{ reiniger.Artikelnummer }}</p>
                <div class="kaufen"><a :href="reiniger.Link" target="_blank" class="button-kaufen">Jetzt Kaufen</a></div>
            </div>
            <div>
                <div class="bilderrahmen"><a :href="reiniger.Link" target="_blank"><img :src="reiniger.Image" class="reinigerbild"></a></div>
            </div>
        </div>
        
    
  </div>
      <button @click="start">Zum Anfang</button>
      <button @click="previousStep">Zurück</button>
    </div>
  </div>
</template>

<script>
import { reinigerData } from "../data/reiniger";

export default {
  data() {
    return {
      currentStep: 1, // Steuert den aktuellen Schritt
      selectedMaterial: "",
      selectedDirtType: "",
      needsProtection: "",
    };
  },
  computed: {
    filteredReiniger() {
      return reinigerData.filter((reiniger) => {
        const matchesMaterial = this.selectedMaterial
          ? reiniger["Oberfläche/Material"].includes(this.selectedMaterial)
          : true;

        const matchesDirtType = this.selectedDirtType
          ? reiniger["Anwendungsbereich"].includes(this.selectedDirtType)
          : true;

        const matchesProtection = this.needsProtection === "Ja" ? reiniger.Schutz : true;

        return matchesMaterial && matchesDirtType && matchesProtection;
      });
    },
    resultCount() {
      return this.filteredReiniger.length;
    },
  },
  methods: {
    nextStep() {
      if (this.currentStep < 4) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    zeigealle() {
      this.currentStep = 4;
    },
    start() {
      this.currentStep = 1;
    
    },
  },
};
</script>

<style scoped>
.grillreiniger-filter {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.step {
  transition: transform 0.3s ease-in-out;
}

select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.reiniger-list {
  margin-top: 20px;
}

.reiniger-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: left;
}

h2 {
  color: green;
  text-decoration:underline;
}

h2:hover{
  cursor: pointer;
}

h3 a {
  text-decoration: none;
  color: #007bff;
}

h3 a:hover {
  text-decoration: underline;
}


.flex{
    display:flex;
    flex-direction: row;
  
}

.reinigerbild {

    width: auto;
    height: 300px;
    display: inline-block; 
}

.button-kaufen {
  padding: 10px 20px;
  margin-top: 40px;
  margin-right: 10px;
  background-color:#dfaa50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;


}
.button-kaufen:hover {
  background-color:black;
}

.kaufen {
  margin-top:60px;
}

.cardlinks{

  flex-grow: 1; /* Die linke Spalte wächst, um den restlichen verfügbaren Platz einzunehmen */
  flex-shrink: 1; /* Die linke Spalte schrumpft bei Bedarf */
  flex-basis: 0;
}

.bilderrahmen {

  min-width: 170px;
  border: solid 0px black;
  text-align: center;
}

</style>
