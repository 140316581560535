<template>
  <GrillReiniger/>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import GrillReiniger from '@/components/GrillReiniger.vue'

export default {
  name: 'GrillReiniger_name',
  components: {
    GrillReiniger
  }
}
</script>