<template>
  <div>
    <nav>
      <!--<router-link to="/">Home</router-link> |
      <router-link to="/about">Grillreiniger</router-link> |
       Comment <router-link to="/config">Einstellungen</router-link>-->
    </nav>
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border: solid 2px black;
  min-height: 90vh;
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #6642b9;
}
</style>
